<!-- /pages/bloqueo-ip.vue -->
<template>
  <div class="error-page">
    <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256">
  <defs>
    <clipPath id="shieldClip">
      <path d="M128 10 L228 50 V130 C228 190 178 230 128 246 C78 230 28 190 28 130 V50 Z"/>
    </clipPath>
  </defs>
  
  <!-- Escudo base -->
  <path d="M128 10 L228 50 V130 C228 190 178 230 128 246 C78 230 28 190 28 130 V50 Z" fill="#38B177"/>
  
  <!-- Sombra del escudo -->
  <path d="M128 10 L28 50 V130 C28 190 78 230 128 246 V10 Z" fill="#2E9764"/>
  
  <!-- Montañas -->
  <g clip-path="url(#shieldClip)">
    <g transform="translate(35, 20) scale(1.5)">
      <path clip-rule="evenodd" d="m55.7498 27.1551c-3.2221-5.5401-11.2775-5.5402-14.4996 0l-35.11616 60.3795c-3.22213 5.5402.80552 12.4654 7.24976 12.4654h27.4137c-2.7537-2.4066-3.7734-6.5697-1.6896-10.1416l26.5954-45.589z" fill="#000000" fill-rule="evenodd"/><path d="m78.0002 40.3997c2.6666-4.5329 9.333-4.5329 11.9996 0l29.0612 49.4013c2.667 4.5329-.666 10.199-5.999 10.199h-58.1237c-5.3331 0-8.6664-5.6661-5.9998-10.199z" fill="#00000"/>
    </g>
  </g>
</svg>
    <h1>429</h1>
    <p>Demasiadas solicitudes, su IP ha sido bloqueada. Por favor, inténtelo más tarde o comuníquese con un
      administrador.</p>
  </div>
</template>

<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  text-align: center;
}

/* 
svg {
  margin-bottom: 20px;
} */

h1 {
  font-size: 6rem;
  margin: 0;
}

p {
  font-size: 1.5rem;
}
</style>
